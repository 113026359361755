.root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .paper {
    padding: 24px;
    max-width: 600px;
    position: relative;
  }
  
  .iconContainer {
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .infoButton {
    background-color: #1db954;
    color: white;
  }
  
  .infoButton:hover {
    background-color: #1ed760;
  }
  
  .icon {
    font-size: 32px;
  }
  