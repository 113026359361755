.root {
  padding: 8px;
  background-color: var(--background);
  border: solid 1px var(--primary) !important;
  outline: none !important;
  box-shadow: var(--shadow);
}

.title {
  display: block;
  margin-bottom: 16px;
  color: var(--text-on-light);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.content {
  display: block;
}

.content > :not(:last-child) {
  margin-bottom: 8px;
}

.contentItem {
  display: block;
  text-shadow: 
    -1px -1px 0 rgba(0, 0, 0, 0.2),
    1px -1px 0 rgba(0, 0, 0, 0.2),
    -1px 1px 0 rgba(0, 0, 0, 0.2),
    1px 1px 0 rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
