body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--content-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 0.9em;
  padding: 4px 8px;
  background-color: var(--code-background);
  border-radius: 8px;
  transition: all 0.2s ease;
}

code:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(29, 185, 84, 0.15);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

* {
  box-sizing: border-box;
}

:root {
  --track-options: 34px;
  --radius: 12px;
  --page-padding: 0 16px 16px 16px;
  --accent: #1ed760;
  --header-image-size: 60px;
  --secondary-dark: rgba(255, 255, 255, 0.2);
  --secondary-dark-hover: rgba(255, 255, 255, 0.3);

  --gradient-purple: rgb(84, 58, 183);
  --gradient-purple-pink: rgb(159, 80, 192);
  --gradient-pink: rgb(230, 87, 182);
  --gradient-coral: rgb(246, 114, 128);
  --gradient-blue: rgb(87, 111, 230);

  --gradient-main: linear-gradient(
    60deg,
    var(--gradient-purple) 0%,
    var(--gradient-purple-pink) 15%,
    var(--gradient-pink) 30%,
    var(--gradient-coral) 45%,
    var(--gradient-blue) 60%,
    var(--gradient-purple) 75%,
    var(--gradient-purple-pink) 90%,
    var(--gradient-pink) 100%
  );

  --gradient-themes: linear-gradient(45deg, var(--gradient-purple) 0%, var(--gradient-blue) 100%);
  --gradient-memes: linear-gradient(45deg, var(--gradient-coral) 0%, var(--gradient-blue) 100%);
  --gradient-insights: linear-gradient(45deg, var(--gradient-purple-pink) 0%, var(--gradient-pink) 100%);
}

.dark-vars {
  --content-border: 1px solid rgba(255, 255, 255, 0.1);
  --hardshadow: 0 8px 32px -5px rgba(0, 0, 0, 0.5);

  --primary: #1DB954;
  --primary-hover: #1ed760;

  --text-grey: rgba(255, 255, 255, 0.7);
  --border-color: rgba(255, 255, 255, 0.1);

  --hover: rgba(255, 255, 255, 0.05);

  --background: #0a0a0a;
  --content-background: #121212;

  --text-on-light: #FFFFFF;
  --text-on-dark: #FFFFFF;
  --code-background: rgba(255, 255, 255, 0.03);

  --divider: rgba(255, 255, 255, 0.1);

  --roast-background: linear-gradient(45deg, #1e1e1e, #2d2d2d);
  --roast-text: #FFFFFF;
  --roast-container-bg: rgba(30, 30, 30, 0.8);
  --roast-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);

  --insight-background: rgba(0, 0, 0, 0.3);
  --insight-text: #FFFFFF;
  --insight-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.light-vars {
  --shadow: 0 8px 24px -5px rgba(29, 185, 84, 0.15);
  --hardshadow: 0 12px 40px -5px rgba(29, 185, 84, 0.2);

  --primary: #1ed760;
  --primary-hover: #1fdf64;

  --text-grey: #727272;
  --border-color: rgba(29, 185, 84, 0.25);

  --hover: rgba(29, 185, 84, 0.15);

  --background: #ffffff;
  --content-background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);

  --text-on-light: #191414;
  --text-on-dark: #FFFFFF;
  --code-background: rgba(29, 185, 84, 0.1);

  --divider: rgba(29, 185, 84, 0.25);

  --roast-background: linear-gradient(45deg, #f5f5f5, #ffffff);
  --roast-text: #191414;
  --roast-container-bg: rgba(255, 255, 255, 0.9);
  --roast-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

  --insight-background: rgba(255, 255, 255, 0.9);
  --insight-text: #191414;
  --insight-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

/* Add subtle transitions for interactive elements */
button, a, .interactive {
  transition: all 0.2s ease;
}

button:hover, a:hover, .interactive:hover {
  transform: translateY(-1px);
}

@media (hover: none) and (pointer: coarse) {
  * {
    user-select: none;
  }
}

@media screen and (max-width: 960px) {
  :root {
    --page-padding: 0px 8px 8px 8px;
    --header-image-size: 40px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0%;
    transform: translateY(10px);
  }
  100% {
    opacity: 100%;
    transform: translateY(0);
  }
}

.fade {
  animation-name: fadein;
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: 1;
}

.no-button {
  cursor: pointer;
  border: none;
  outline: none;
  font-size: unset;
  color: unset;
  background: none;
  padding: 8px 16px;
  border-radius: var(--radius);
  transition: all 0.2s ease;
}

.no-button:hover {
  background-color: var(--hover);
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.stats-item {
  margin-bottom: 12px;
  padding: 12px;
  border-radius: var(--radius);
  background: rgba(255, 255, 255, 0.03);
  transition: all 0.2s ease;
}

.stats-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.05);
}

/* Add for card-like elements */
.card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
}

.card:hover {
  background: rgba(255, 255, 255, 0.05);
}