.insight-item {
  padding: 12px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  background: var(--insight-background);
  color: var(--insight-text);
  box-shadow: var(--insight-shadow);
  margin-bottom: 8px;
}

.insight-item:hover {
  background: rgba(255, 255, 255, 0.07);
  transform: translateX(4px);
}

.era-item {
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--insight-background);
  color: var(--insight-text);
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.era-item:hover {
  background: rgba(255, 255, 255, 0.07);
}

.percentage {
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 16px;
  font-weight: 500;
}

.summary {
  font-size: 1.1em;
  line-height: 1.6;
  padding: 16px;
  border-radius: 8px;
  background: var(--insight-background);
  color: var(--insight-text);
  margin-bottom: 24px;
}

.discoveryItem {
  padding: 16px;
  border-radius: 8px;
  background: var(--insight-background);
  backdrop-filter: blur(10px);
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.discoveryArtist {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 4px;
}

.discoveryReason {
  color: var(--insight-text);
  font-size: 1rem;
  line-height: 1.4;
}

.surprising {
  padding: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
  border-left: 4px solid #6366f1;
}

.content {
  padding: var(--page-padding);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: var(--background);
  color: var(--text-on-light);
  padding: 2rem;
  text-align: center;
}

.loadingMessage {
  margin-top: 2rem;
  font-size: 1.5rem;
  opacity: 0.9;
  color: var(--text-on-light);
}

.loadingSubtext {
  margin-top: 1rem;
  font-size: 1rem;
  opacity: 0.7;
  color: var(--text-grey);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.insightItem {
  padding: 1rem;
  background: var(--insight-background);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  color: var(--insight-text);
}

.genresContainer {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.genresContainer .insightItem {
  padding: 1rem;
  background: var(--insight-background);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  color: var(--insight-text);
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  transition: transform 0.2s ease;
}

.genresContainer .insightItem:hover {
  transform: translateX(5px);
  background: rgba(0, 0, 0, 0.4);
}

.summary {
  font-size: 1.8rem;
  max-width: 800px;
  line-height: 1.4;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

@media (max-width: 768px) {
  .summary {
    font-size: 1.4rem;
    padding: 1.5rem;
  }

  .insightItem {
    padding: 1rem;
  }
}

.timeRangeSelector {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  background: var(--insight-background);
  padding: 0.5rem 1rem;
  border-radius: 0 0 8px 8px;
  backdrop-filter: blur(8px);
  color: var(--text-on-light);
}

.slideshowContainer {
  padding-top: 60px;
}

.slide {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem 2rem;
  color: white;
  cursor: pointer;
  text-align: center;
  overflow-y: auto;
}

.slideTitle {
  margin-top: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  background: var(--background);
  color: var(--text-on-light);
  position: relative;
  overflow: hidden;
}

.backgroundAnimation {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.15;
  filter: blur(60px);
  animation: float 20s infinite ease-in-out;
  mix-blend-mode: screen;
}

/* Dark theme (default) */
.circle {
  background: linear-gradient(45deg, var(--primary), transparent);
}

/* Light theme adjustments */
:global(.light-vars) .circle {
  background: linear-gradient(45deg, var(--primary), var(--primary-hover));
  opacity: 0.2;
  mix-blend-mode: multiply;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(50px, 50px) rotate(90deg);
  }
  50% {
    transform: translate(0, 100px) rotate(180deg);
  }
  75% {
    transform: translate(-50px, 50px) rotate(270deg);
  }
}

.viewButton {
  font-size: 1.2rem !important;
  padding: 1.2rem 3rem !important;
  border-radius: 12px !important;
  background: linear-gradient(45deg, #4a90e2, #63b3ed) !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.viewButton:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(255,255,255,0.2), transparent);
  transition: 0.5s;
  z-index: -1;
}

.viewButton:hover:before {
  left: 100%;
}

.viewButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: var(--background);
  color: var(--text-on-light);
  padding: 2rem;
  text-align: center;
}

.loadingMessage {
  margin-top: 2rem;
  font-size: 1.5rem;
  opacity: 0.9;
}

.content {
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
}

.nextStepsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

.nextStepsContent {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  text-align: center;
  max-width: 600px;
  width: 100%;
}

.nextStepsText {
  font-size: 2rem !important;
  line-height: 1.4 !important;
  margin-bottom: 3rem !important;
  color: white;
}

.roastButton {
  font-size: 1.2rem !important;
  padding: 1rem 3rem !important;
  border-radius: 12px !important;
  background: rgba(255, 255, 255, 0.15) !important;
  color: white !important;
  text-transform: none !important;
  transition: all 0.2s ease !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  min-width: 250px !important;
}

.roastButton:hover {
  background: rgba(255, 255, 255, 0.25) !important;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.musicalNotes {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  overflow: hidden;
}

.note {
  position: absolute;
  font-size: 3rem;
  color: var(--primary);
  opacity: 0.4;
  text-shadow: 0 0 15px var(--primary);
  animation: floatNote 12s linear infinite;
}

/* Position notes at different starting points */
.note:nth-child(1) { left: 15%; animation-delay: 0s; }
.note:nth-child(2) { left: 35%; animation-delay: -2.4s; }
.note:nth-child(3) { left: 55%; animation-delay: -4.8s; }
.note:nth-child(4) { left: 75%; animation-delay: -7.2s; }
.note:nth-child(5) { left: 95%; animation-delay: -9.6s; }

/* Alternate between different musical symbols */
.note:nth-child(odd)::before { content: '♪'; }
.note:nth-child(even)::before { content: '♫'; }

@keyframes floatNote {
  0% {
    transform: translateY(120vh) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(-20vh) rotate(360deg);
    opacity: 0;
  }
}

/* Light theme adjustments */
:global(.light-vars) .note {
  opacity: 0.5;
  color: var(--primary);
  text-shadow: 0 0 20px var(--primary);
}

.pulsingButton {
  position: relative;
}

.pulsingButton::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.viewButton {
  background: linear-gradient(-45deg, #4a90e2, #63b3ed, #805ad5, #4a90e2) !important;
  background-size: 400% 400% !important;
  animation: gradientBG 15s ease infinite !important;
  box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3),
              0 0 30px rgba(74, 144, 226, 0.2),
              0 0 50px rgba(74, 144, 226, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  transition: all 0.3s ease !important;
  padding: 15px 40px !important;
  font-size: 1.3rem !important;
  letter-spacing: 1px !important;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.viewButton:hover {
  transform: translateY(-3px) scale(1.02) !important;
  box-shadow: 0 6px 20px rgba(74, 144, 226, 0.4),
              0 0 40px rgba(74, 144, 226, 0.3),
              0 0 60px rgba(74, 144, 226, 0.2) !important;
}

.visualEffects {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.sparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: sparkle 4s linear infinite;
}

@keyframes sparkle {
  0%, 100% { opacity: 0; transform: scale(0); }
  50% { opacity: 0.8; transform: scale(1); }
}

.sparkle:nth-child(1) { left: 10%; top: 20%; animation-delay: 0s; }
.sparkle:nth-child(2) { left: 20%; top: 40%; animation-delay: 0.3s; }
.sparkle:nth-child(3) { left: 30%; top: 60%; animation-delay: 0.6s; }
.sparkle:nth-child(4) { left: 40%; top: 80%; animation-delay: 0.9s; }
.sparkle:nth-child(5) { left: 60%; top: 20%; animation-delay: 1.2s; }
.sparkle:nth-child(6) { left: 70%; top: 40%; animation-delay: 1.5s; }
.sparkle:nth-child(7) { left: 80%; top: 60%; animation-delay: 1.8s; }
.sparkle:nth-child(8) { left: 90%; top: 80%; animation-delay: 2.1s; }

.equalizer {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 6px;
  z-index: 1;
}

.bar {
  width: 6px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  animation: equalize 1.5s ease-in-out infinite;
}

.bar:nth-child(1) { animation-delay: 0s; }
.bar:nth-child(2) { animation-delay: 0.2s; }
.bar:nth-child(3) { animation-delay: 0.4s; }
.bar:nth-child(4) { animation-delay: 0.6s; }
.bar:nth-child(5) { animation-delay: 0.8s; }

@keyframes equalize {
  0%, 100% { height: 20px; }
  50% { height: 40px; }
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(transparent, rgba(74, 144, 226, 0.1));
  animation: wave 8s linear infinite;
}

@keyframes wave {
  0% { transform: translateX(0); }
  50% { transform: translateX(-50%); }
  100% { transform: translateX(0); }
}
