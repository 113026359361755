.nextStepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
}

.nextStepsContent {
  text-align: center;
  max-width: 600px;
}

.nextStepsText {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.nextStepsButton {
  font-size: 1.2rem !important;
  padding: 12px 24px !important;
  border-radius: 30px !important;
}
