.slideshowContainer {
  height: 100vh;
  width: calc(100vw - 250px);
  position: fixed;
  top: 0;
  left: 250px;
  overflow: hidden;
  touch-action: none;
  z-index: 100;
}

.slide {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem 2rem;
  color: white;
  cursor: pointer;
  text-align: center;
  overflow-y: auto;
}

.slideTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 1rem 0;
  padding: 0 3rem;
}

.slideContent {
  width: 100%;
  max-width: 800px;
  padding: 0 3rem;
}

.controls {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 20;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.25rem;
  border-radius: 8px;
  backdrop-filter: blur(8px);
}

.progressBar {
  position: fixed;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.25rem;
  z-index: 20;
  padding: 0.25rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  backdrop-filter: blur(8px);
}

.progressDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
}

.progressDot.active {
  background: white;
  transform: scale(1.2);
}

.navigationControls {
  position: fixed;
  top: 50%;
  left: 250px;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 1000;
  pointer-events: none;
}

.navButton {
  pointer-events: auto;
  width: 40px !important;
  height: 40px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  color: white !important;
  border-radius: 50% !important;
  backdrop-filter: blur(8px);
}

.navButton:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}

.navButton.hidden {
  opacity: 0;
  pointer-events: none;
}

.controlButton {
  padding: 6px !important;
  width: 32px !important;
  height: 32px !important;
  min-width: unset !important;
}

.controlButton:hover {
  background: rgba(255, 255, 255, 0.3) !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navigationControls {
    left: 0;
    padding: 0 0.5rem;
  }

  .navButton {
    width: 36px !important;
    height: 36px !important;
  }

  .slideshowContainer {
    width: 100vw !important;
    left: 0 !important;
  }

  .slide {
    padding: 3rem 1rem 1rem;
  }

  .slideTitle {
    font-size: 2rem;
    padding: 0 1rem;
  }

  .slideContent {
    padding: 0 1rem;
  }

  .controls {
    right: 0.5rem;
  }

  .progressBar {
    left: 50%;
    transform: translateX(-50%);
    padding: 0.25rem;
  }
}

.watermark {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  backdrop-filter: blur(8px);
  z-index: 20;
  pointer-events: none;
  user-select: none;
}
