.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: var(--background);
  color: var(--text-on-light);
  padding: 2rem;
  text-align: center;
}

.loadingMessage {
  margin-top: 2rem;
  font-size: 1.5rem;
  opacity: 0.9;
  color: var(--text-on-light);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.loadingSubtext {
  margin-top: 1rem;
  font-size: 1rem;
  opacity: 0.7;
  color: var(--text-grey);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.content {
  padding: 1rem;
}

.roastLevelContainer {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: var(--roast-container-bg);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid var(--border-color);
  z-index: 1;
}

.roastLevelSelector {
  width: 100%;
  padding: 1rem;
}

.roastLevelSelector select {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(40, 40, 40, 0.9);
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.roastLevelSelector select:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(50, 50, 50, 0.9);
}

.roastLevelSelector select:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.overallRoast {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
  text-align: center;
}

.roast-item {
  padding: 1rem;
  border-radius: 4px;
  background-color: var(--background-light);
  margin-bottom: 1rem;
}

.roastTarget {
  font-weight: bold;
  color: var(--primary);
  margin-right: 12px;
  display: inline-block;
}

.meme-item {
  padding: 1rem;
  border-radius: 4px;
  background-color: var(--background-light);
  margin-bottom: 1rem;
  font-style: italic;
}

.quality-item {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--background-success-light);
  margin-bottom: 0.5rem;
}

.shareSection {
  text-align: center;
}

.sharingCaption {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: 4px;
}

.metadata {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.metadataItem {
  text-align: center;
}

.score {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
}

.share-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 16px;
}

.sharingCaption {
  font-size: 1.1rem;
  text-align: center;
  padding: 16px;
  background: var(--background-elevated);
  border-radius: 8px;
  margin-bottom: 16px;
}

.shareButtons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.twitterButton {
  color: #1DA1F2 !important;
}

.facebookButton {
  color: #4267B2 !important;
}

.redditButton {
  color: #FF4500 !important;
}

/* Add hover effects */
.shareButtons button:hover {
  transform: translateY(-2px);
  opacity: 0.9;
}

@media (hover: none) {
  .shareButtons button:hover {
    transform: none;
  }
}

.container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.backgroundAnimation {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.ember {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #ff6b6b;
  border-radius: 50%;
  filter: blur(1px);
  animation: float 3s ease-in infinite;
}

@keyframes float {
  0% {
    transform: translateY(100vh) rotate(0deg);
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-100px) rotate(360deg);
    opacity: 0;
  }
}

.flameCircle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle at center, #ff6b6b 0%, transparent 70%);
  opacity: 0.1;
  filter: blur(60px);
  animation: pulse 4s ease-in-out infinite;
}

.flameCircle:nth-child(1) {
  width: 400px;
  height: 400px;
  top: -200px;
  left: -200px;
  animation-delay: 0s;
}

.flameCircle:nth-child(2) {
  width: 600px;
  height: 600px;
  bottom: -300px;
  right: -300px;
  animation-delay: -2s;
}

.startButton {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.viewButton {
  width: 100% !important;
  background: #ff6b6b !important;
  color: white !important;
  font-size: 1.2rem !important;
  padding: 1rem !important;
  border-radius: 12px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 0 20px rgba(255, 107, 107, 0.3) !important;
}

.viewButton:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 0 30px rgba(255, 107, 107, 0.5) !important;
}

.flames {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  z-index: 0;
}

.flame {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  background: #ff6b6b;
  border-radius: 50% 0 50% 50%;
  transform: rotate(45deg);
  filter: blur(2px);
  animation: flicker 1s ease-in-out infinite alternate;
}

@keyframes flicker {
  0% { transform: rotate(45deg) scale(1); }
  100% { transform: rotate(45deg) scale(1.2); }
}

.content {
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
}

.nextStepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 0 16px;
  }

  .roastLevelContainer {
    padding: 1.5rem;
    margin: 1rem auto;
  }

  .startButton {
    margin: 1rem auto;
  }

  .viewButton {
    font-size: 1rem !important;
    padding: 0.8rem !important;
  }
}

/* Make sure the header content is centered */
.header {
  width: 100%;
  padding: 0 20px;
}

/* If you need responsive padding, you can use media queries */
@media (max-width: 768px) {
  .header {
    padding: 0 16px;
  }
}

/* Add theme-specific styles */
:global(.light-vars) .roastLevelContainer {
  background: var(--roast-container-bg);
  border-color: var(--border-color);
}

:global(.light-vars) .viewButton {
  background: #ff6b6b !important;
  color: white !important;
}

:global(.light-vars) .ember {
  background: #ff8f8f;
}

:global(.light-vars) .flameCircle {
  background: radial-gradient(circle at center, #ff8f8f 0%, transparent 70%);
}

:global(.light-vars) .container {
  background: var(--roast-background);
}
