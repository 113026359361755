.notice {
  font-size: 0.8em;
  color: var(--text-on-light);
  padding: 4px 8px;
  margin-bottom: 8px;
  text-align: center;
}

.daysAgo {
  display: block;
  font-size: 0.9em;
  color: var(--text-grey);
  margin-top: 2px;
}

.infoIcon {
  vertical-align: middle;
  margin-left: 4px;
  opacity: 0.7;
  cursor: help;
}

.infoIcon:hover {
  opacity: 1;
}
