.root {
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.95) 0%,
    rgba(20, 20, 20, 0.95) 100%
  );
  backdrop-filter: blur(10px);
  color: white;
  padding: 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.root::-webkit-scrollbar {
  display: none;
}

.drawer {
  width: 300px;
}

.title {
  display: block;
  margin-top: 8px;
  margin-bottom: 32px;
  margin-left: 8px;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  background: var(--gradient-main);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.versionwrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-left: 8px;
}

.version {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8em;
  transform: translateY(-1px);
}
