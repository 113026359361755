.category {
  margin-bottom: 24px;
  position: relative;
}

.categoryname {
  margin-bottom: 8px;
  margin-left: 12px;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.link {
  display: flex;
  margin-bottom: 2px;
  padding: 10px 12px;
  color: white;
  text-decoration: none;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.linkcontent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    60deg,
    var(--gradient-purple) 0%,
    var(--gradient-blue) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.3s ease;
}

.linkcontent > :first-child {
  margin-right: 12px;
  font-size: 1.25em;
}

.link:hover .linkcontent {
  background-position: 0 0;
}

.link:has(.active) {
  background: rgba(255, 255, 255, 0.1);
}

.active {
  background: var(--gradient-main);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.sparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  filter: blur(1px);
  pointer-events: none;
  animation: sparkleAnim 2s linear infinite;
}

@keyframes sparkleAnim {
  0%, 100% { opacity: 0; transform: scale(0); }
  50% { opacity: 1; transform: scale(1); }
}
