.root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1a1a;
  color: white;
}

.content {
  text-align: center;
  padding: 2rem;
}

.content h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #1DB954, #1ed760);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content p {
  color: #b3b3b3;
  margin-bottom: 1rem;
}

.spinner {
  color: #1DB954 !important;
  margin-top: 2rem;
}

.button {
  margin-top: 2rem !important;
  background-color: #1DB954 !important;
}

.button:hover {
  background-color: #1ed760 !important;
}
