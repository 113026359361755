.root {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.animated_gradient_wave {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(159, 80, 192, 1) 15%,
    rgba(230, 87, 182, 1) 30%,
    rgba(246, 114, 128, 1) 45%,
    rgba(87, 111, 230, 1) 60%,
    rgba(84, 58, 183, 1) 75%,
    rgba(159, 80, 192, 1) 90%,
    rgba(230, 87, 182, 1) 100%
  );
  background-size: 300% 300%;
  animation: gradientShift 20s ease infinite;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
}

.animated_gradient_wave::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    -45deg,
    transparent 0%,
    transparent 48%,
    rgba(255, 255, 255, 0.1) 49%,
    rgba(255, 255, 255, 0.2) 51%,
    transparent 52%,
    transparent 100%
  );
  background-size: 300% 300%;
  animation: gradientShift 15s ease infinite;
  mix-blend-mode: soft-light;
}

.animated_gradient_wave::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    transparent 48%,
    rgba(255, 255, 255, 0.05) 49%,
    rgba(255, 255, 255, 0.15) 51%,
    transparent 52%,
    transparent 100%
  );
  background-size: 300% 300%;
  animation: gradientShift 10s ease infinite;
  mix-blend-mode: soft-light;
}

.wave_container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateY(60%) rotate(-15deg);
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: 0 bottom;
  transform-origin: center bottom;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23ffffff'/%3E%3C/svg%3E");
  animation: wave 20s linear infinite;
  opacity: 0.1;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.8);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.content {
  width: 100%;
  max-width: 800px;
  padding: 2.5rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
}

.highlight {
  color: #1DB954;
  mix-blend-mode: difference;
  display: inline-block;
  position: relative;
  z-index: 2;
  font-weight: bold;
}

.subtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-bottom: 2rem;
}

.featuresContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.features {
  flex: 1;
}

.features h2 {
  color: #fff;
  margin-bottom: 1rem;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  display: flex;
  align-items: center;
  color: #e0e0e0;
  margin-bottom: 1rem;
}

.features li svg {
  margin-right: 0.5rem;
  color: #1DB954;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ctaButton {
  background-color: #1DB954;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.ctaButton:hover {
  background-color: #1ed760;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.info {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

.loggedIn {
  text-align: center;
}

.welcome {
  color: #fff;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .featuresContainer {
    flex-direction: column;
  }
}
